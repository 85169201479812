import { c, PolymorphicComponentPropsWithRef, PolymorphicRef } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { ElementType, forwardRef } from 'react'

const buttonStyle = {
  primary: `text-white bg-primary-600 hover:bg-primary-700 disabled:hover:bg-primary-600 focus:ring-primary-500`,
  primaryGhost: `text-primary-600 bg-transparent hover:text-primary-500 disabled:hover:bg-primary-100 focus:ring-primary-500`,
  secondary: `text-primary-700 bg-primary-100 hover:bg-primary-200 disabled:hover:bg-primary-100 focus:ring-primary-500`,
  danger: `text-white bg-red-600 hover:bg-red-700 disabled:hover:bg-red-600 focus:ring-red-500`,
  dangerSecondary: `text-red-700 bg-red-100 hover:bg-red-200 disabled:hover:bg-red-100 focus:ring-red-500`,
  dangerGhost: `text-red-500 bg-transparent hover:text-red-400 disabled:hover:text-red-500 focus:ring-red-500`,
  white: `text-black bg-white !border-gray-300 hover:bg-gray-50 disabled:hover:bg-white focus:ring-primary-500`,
  gray: `text-gray-500 bg-transparent hover:text-gray-700 disabled:hover:text-gray-500 focus:!ring-0 focus:!ring-offset-0`,
  accent: `text-black bg-lime-300 hover:bg-lime-400 focus:ring-lime-300 disabled:hover:bg-lime-300`,
  sellematicsAccent: `text-black bg-yellow-300 hover:bg-yellow-400 focus:ring-yellow-300 disabled:hover:bg-yellow-300`,
} as const

type Props<T extends ElementType> = PolymorphicComponentPropsWithRef<
  T,
  {
    theme: keyof typeof buttonStyle
  }
>

const Button = <T extends ElementType = 'button'>(
  { as, theme, children, className, ...rest }: Props<T>,
  ref?: PolymorphicRef<T>
) => {
  const Component = as ?? 'button'

  const ctx = useUserContext()

  let style = buttonStyle[theme]
  if (ctx.theme.hostType === 'sellematics' && theme === 'accent') {
    style = buttonStyle.sellematicsAccent
  }

  return (
    <Component
      ref={ref}
      type="button"
      className={c`inline-flex appearance-none items-center px-4 py-2 text-sm font-medium space-x-2 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 no-underline disabled:cursor-not-allowed disabled:saturate-50 disabled:brightness-150 disabled:opacity-50 ${style} ${className}`}
      {...rest}
    >
      {children}
    </Component>
  )
}

export default forwardRef(Button) as typeof Button
