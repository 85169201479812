import { ListboxOption, ListboxOptions } from '@headlessui/react'
import { AnchorPropsWithSelection } from '@headlessui/react/dist/internal/floating'
import { CheckIcon } from '@heroicons/react/solid'
import { c } from 'lib/component-utils'
import { DisplayExtractor, extract, KeyExtractor } from 'lib/type-utils'
import { ForwardedRef, forwardRef } from 'react'

type Props<T> = {
  options: T[]
  optionKey?: KeyExtractor<T>
  display?: DisplayExtractor<T>
  className?: string
  anchor?: AnchorPropsWithSelection
}

const DropdownMenu = <T,>(
  { options, optionKey, display, className, anchor }: Props<T>,
  ref: ForwardedRef<HTMLUListElement>
) => {
  return (
    <ListboxOptions
      ref={ref}
      anchor={{ to: 'bottom start', gap: 8, padding: 16, ...((anchor as any) || {}) }}
      className={c`bg-white !max-h-64 h-fit overscroll-contain ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20 w-64 overflow-auto text-base rounded-md shadow-lg ${className}`}
    >
      {options.map((option) => (
        <ListboxOption
          key={extract(option, optionKey)}
          className={({ focus }) =>
            c`cursor-pointer select-none relative py-2.5 pl-8 pr-4 text-left ${focus} text-black bg-gray-100 | text-gray-900`
          }
          value={option}
        >
          {({ selected, focus }) => (
            <>
              <span className={c`block truncate ${selected} font-medium`}>
                {extract(option, display)}
              </span>
              {selected && (
                <span
                  className={c`absolute inset-y-0 left-0 flex items-center pl-3 z-10 text-primary-500 ${focus} !text-primary-600`}
                >
                  <CheckIcon className="w-4 h-4" aria-hidden="true" />
                </span>
              )}
            </>
          )}
        </ListboxOption>
      ))}
    </ListboxOptions>
  )
}

export default forwardRef<HTMLUListElement, {}>(DropdownMenu as any) as <T>(
  { options, optionKey, display, className }: Props<T>,
  ref: ForwardedRef<HTMLUListElement>
) => JSX.Element
