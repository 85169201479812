import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import { c, FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'
import { useEffect, useState } from 'react'
import { InstagramIcon, TelegramIcon, WeChatIcon, WhatsAppIcon } from 'components/icons'
import { useRouter } from 'next/router'

const links = [
  {
    name: 'Telegram',
    prefix: 'https://t.me/',
    href: (c: any) => c.telegram,
    icon: TelegramIcon,
  },
  { name: 'WhatsApp', prefix: 'https://wa.me/', href: (c: any) => c.whatsapp, icon: WhatsAppIcon },
  {
    name: 'WeChat',
    prefix: 'weixin://dl/chat?',
    href: (c: any) => c.wechat,
    icon: WeChatIcon,
  },
  {
    name: 'Instagram',
    prefix: 'https://instagram.com/',
    href: (c: any) => c.instagram,
    icon: InstagramIcon,
  },
  {
    name: 'Email',
    prefix: 'mailto:',
    href: (c: any) => c.mail,
    icon: MailIcon,
  },
  {
    name: 'Phone',
    prefix: 'tel:',
    href: (c: any) => c.phone,
    icon: PhoneIcon,
  },
]

const Footer: FC = ({ className }) => {
  const { t, lang } = useIntl()
  const { theme } = useUserContext()
  const router = useRouter()
  const [hide, setHide] = useState(false)

  useEffect(() => {
    const iframe = document.querySelector('main > div > iframe')

    if (iframe !== null) {
      setHide(true)
    } else {
      setHide(false)
    }
  }, [router.asPath])

  if (hide) {
    return null
  }

  const currentYear = new Date().getFullYear()
  const lifespan =
    theme.releaseDate !== currentYear ? theme.releaseDate + '–' + currentYear : theme.releaseDate

  return (
    <footer className={c`sticky z-10 bg-white border-t border-gray-200 top-[100vh] ${className}`}>
      <div
        className={c`relative flex justify-between items-start px-8 py-6 mx-auto flex-col space-y-8 sm:space-y-0 sm:flex-row ${[
          'sellmonitor',
          'sellscreen',
        ].includes(theme.hostType)} sm:items-start | !space-y-20 sm:!space-y-0`}
      >
        <div
          className={c`${['sellmonitor', 'sellscreen', 'uzum', 'psb'].includes(
            theme.hostType
          )} space-y-4`}
        >
          <img
            src={theme.logoFixed}
            className={c`${
              theme.hostType === 'sellematics'
            } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-12 | w-14`}
            alt={`${theme.title} logo`}
          />
          <div className="">
            {lang === 'en' && (
              <p className="text-base text-gray-400">
                &copy; {lifespan} {theme.title}. All rights reserved.
              </p>
            )}
            {lang === 'ru' && (
              <p className="text-base text-gray-400">
                &copy; {theme.title}, {lifespan}. Все права защищены.
              </p>
            )}
            {lang === 'uz' && (
              <p className="text-base text-gray-400">
                &copy; {lifespan} {theme.title}. Barcha huquqlar himoyalangan.
              </p>
            )}
            {lang === 'zh' && (
              <p className="text-base text-gray-400">
                &copy; {lifespan} {theme.title}. 版权所有.
              </p>
            )}
          </div>
          {theme.hostType === 'sellscreen' && (
            <div className="flex space-x-6 space-y-0 w-max text-gray-400 lg:mt-auto">
              <a className="hover:text-gray-500" href="/doc/sellscreen/en/terms.pdf">
                {t`footer.terms_of_service`}
              </a>
              <a className="hover:text-gray-500" href="/doc/sellscreen/en/privacy_policy.pdf">
                {t`footer.privacy_policy`}
              </a>
            </div>
          )}
        </div>
        <div className="flex flex-col items-start mx-auto space-y-4 sm:ml-auto sm:mr-0 sm:items-end">
          {theme.hostType === 'sellscreen' && (
            <a
              href={`weixin://dl/chat?${theme.contactInformation.wechat}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/next/img/qr/sellscreen_wechat_support.png"
                className="w-36 h-36 ring-4 mx-1.5 rounded ring-lime-300 ring-offset-4"
                alt="微信"
              />
            </a>
          )}
          {/* <div className="flex justify-center -m-3 translate-y-[3px]">
            {links.map((item) => {
              const link = item.href(theme.contactInformation)

              if (!link) {
                return null
              }
              if (item.name === 'Instagram' && theme.hostType !== 'sellscreen') {
                return null
              }

              return (
                <a
                  key={item.name}
                  href={item.prefix + link}
                  target="_blank"
                  rel="noreferrer"
                  className="p-3 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="w-6 h-6" aria-hidden="true" />
                </a>
              )
            })}
          </div> */}
          {theme.hostType === 'sellmonitor' && (
            <p className="invisible text-base text-gray-400">
              {t`contact_us_via_telegram`} Telegram.
            </p>
          )}
          {theme.hostType === 'sellscreen' && (
            <p className="text-base -mr-2 text-gray-400">{t`contact_us_via_telegram`} 微信。</p>
          )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
