import {
  RegistrationResponseJSON,
  platformAuthenticatorIsAvailable,
  startRegistration,
} from '@simplewebauthn/browser'
import { FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useCallback, useEffect, useState } from 'react'
import { useStateFromLocalStorage } from 'lib/hooks'
import Card from 'components/base/Card'
import Button from 'components/base/Button'
import { FingerPrintIcon, XIcon } from '@heroicons/react/outline'
import { useToast } from 'components/app/NotificationArea'
import { useIntl } from 'lib/intl-utils'
import dayjs from 'dayjs'
import { loginSignalAtom } from './lib/atoms'
import { useAtomValue } from 'jotai'
import { ISO8601DateString } from 'lib/dto'

const WebauthnUpgradeBanner: FC = () => {
  const ctx = useUserContext()
  const { t } = useIntl()
  const [state, setState] = useState<'visible' | 'closing' | 'hidden'>('hidden')
  const [isAdding, setIsAdding] = useState(false)
  const loginSignal = useAtomValue(loginSignalAtom)
  const [closedWebauthnUpgradeBanner, setClosedWebauthnUpgradeBanner] = useStateFromLocalStorage(
    'closedWebauthnUpgradeBanner',
    false
  )
  const [platformAuthenticatorAvailable, setPlatformAuthenticatorAvailable] = useState<
    boolean | null
  >(null)
  const [thisDeviceAuthenticators, setThisDeviceAuthenticators] = useStateFromLocalStorage(
    'webauthnAuthenticators',
    []
  )
  const [lastLoginType, setLastLoginType] = useState<'webauthn' | 'username' | 'vk' | null>(null)
  const [lastLoginDate, setLastLoginDate] = useState<ISO8601DateString | null>(null)
  const showToast = useToast()

  useEffect(() => {
    platformAuthenticatorIsAvailable().then(setPlatformAuthenticatorAvailable)
  }, [])

  useEffect(() => {
    const lastLoginType = document.cookie
      .split('; ')
      .find((row) => row.startsWith('lastLoginType='))
    if (lastLoginType) {
      const [type] = lastLoginType.split('=')[1].split(',')
      setLastLoginType(type as 'webauthn' | 'username' | 'vk')
    }
    const lastLoginDate = document.cookie
      .split('; ')
      .find((row) => row.startsWith('lastLoginDate='))
    if (lastLoginDate) {
      const [date] = lastLoginDate.split('=')[1].split(',')
      setLastLoginDate(date as ISO8601DateString)
    }
  }, [])

  useEffect(() => {
    if (
      ctx.theme.hostType === 'sellmonitor' &&
      lastLoginType === 'username' &&
      lastLoginDate &&
      dayjs().diff(dayjs.unix(parseInt(lastLoginDate)), 'minutes') < 2 &&
      thisDeviceAuthenticators.length === 0 &&
      !closedWebauthnUpgradeBanner &&
      platformAuthenticatorAvailable
    ) {
      setState('visible')
    }
  }, [
    loginSignal,
    lastLoginType,
    lastLoginDate,
    closedWebauthnUpgradeBanner,
    platformAuthenticatorAvailable,
    thisDeviceAuthenticators,
  ])

  const addAuthenticator = useCallback(async () => {
    setIsAdding(true)
    const [options, authenticators] = await Promise.all([
      ctx.getAuthenticatorAddOptions(),
      ctx.getAuthenticatorsList(),
    ])
    let credential: RegistrationResponseJSON | null = null
    try {
      credential = await startRegistration({
        optionsJSON: options,
      })
    } catch (error: any) {
      if (error.name === 'InvalidStateError') {
        showToast('error', t`webauthn.already_added`)
        setIsAdding(false)
      }
    }
    if (credential) {
      ctx.addAuthenticator(credential as any).then((res) => {
        if (res.status === 'ok') {
          showToast('success', t`webauthn.added`)
          ctx.getAuthenticatorsList().then((res) => {
            const newAuthenticators = res.filter((a) => !authenticators?.some((b) => b.id === a.id))
            setThisDeviceAuthenticators([...thisDeviceAuthenticators, ...newAuthenticators])
          })
          setClosedWebauthnUpgradeBanner(true)
          setState('hidden')
        } else {
          showToast('error', t`error`)
        }
        setIsAdding(false)
      })
    }
  }, [ctx, t, showToast, thisDeviceAuthenticators, setThisDeviceAuthenticators])

  if (state === 'hidden') {
    return null
  }

  if (state === 'closing') {
    return (
      <Card className="fixed bottom-4 left-20 large-sidebar:left-60 bg-white px-5 py-3 z-[1010] !shadow-xl">
        <div className="flex items-center gap-2">{t`webauthn.enable_in_settings`}</div>
      </Card>
    )
  }

  return (
    <Card className="fixed bottom-6 left-[72px] large-sidebar:left-60 bg-white px-5 py-3 z-[1010] !shadow-xl">
      <button
        className="absolute top-3 right-4 text-gray-500 hover:text-gray-700"
        onClick={() => {
          setClosedWebauthnUpgradeBanner(true)
          setState('closing')
          setTimeout(() => {
            setState('hidden')
          }, 3000)
        }}
      >
        <XIcon className="size-4" />
      </button>
      <div className="flex items-center gap-2">
        <FingerPrintIcon className="size-8" />
        <div>
          <h3 className="text-sm font-medium">{t`webauthn.cta`}</h3>
          <p className="text-sm text-gray-600">{t`webauthn.cta_description`}</p>
        </div>
      </div>
      <Button theme="primary" className="mt-4" onClick={addAuthenticator} disabled={isAdding}>
        {t`webauthn.enable`}
      </Button>
    </Card>
  )
}

export default WebauthnUpgradeBanner
