import { FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useState } from 'react'
import Card from 'components/base/Card'
import { XIcon } from '@heroicons/react/outline'
import { useIntl } from 'lib/intl-utils'
import { useEffectOnUpdate, useStateFromLocalStorage } from 'lib/hooks'
import dayjs from 'dayjs'
import { Changelog } from 'lib/strapi'

const ChangelogNotifier: FC = () => {
  const ctx = useUserContext()
  const { t } = useIntl()
  const [isShown, setIsShown] = useState(false)
  const [changelogState, setChangelogState] = useStateFromLocalStorage('changelogState', {
    lastClosed: null,
    lastOpened: null,
    lastChecked: null,
    lastCheckLatestEntryDate: null,
  })
  const [entry, setEntry] = useState<Changelog | null>(null)
  const [checkedThisTime, setCheckedThisTime] = useState(false)

  useEffectOnUpdate(() => {
    if (!changelogState.lastClosed) {
      setChangelogState((state) => ({
        ...state,
        lastClosed: dayjs().subtract(3, 'day').toISOString(),
      }))
    }
    if (!changelogState.lastOpened) {
      setChangelogState((state) => ({
        ...state,
        lastOpened: dayjs().subtract(3, 'day').toISOString(),
      }))
    }
  }, [changelogState])

  useEffectOnUpdate(() => {
    if (
      !changelogState.lastChecked ||
      dayjs().diff(changelogState.lastChecked, 'minutes') > 60 ||
      (dayjs(changelogState.lastCheckLatestEntryDate).isAfter(changelogState.lastClosed) &&
        !checkedThisTime)
    ) {
      ctx.getChangelogEntries(1, []).then(({ items }) => {
        if (
          !changelogState.lastClosed ||
          dayjs(changelogState.lastClosed).isBefore(dayjs(items[0].changeDate))
        ) {
          setIsShown(true)
          setEntry(items[0])
          setChangelogState((state) => ({
            ...state,
            lastCheckLatestEntryDate: items[0].changeDate,
          }))
        } else {
          setChangelogState((state) => ({
            ...state,
            lastCheckLatestEntryDate: items[0].changeDate,
          }))
        }
      })
      setChangelogState((state) => ({
        ...state,
        lastChecked: dayjs().toISOString(),
      }))
      setCheckedThisTime(true)
    }
  }, [changelogState])

  if (!isShown) {
    return null
  }

  return (
    <Card className="fixed bottom-6 left-[72px] large-sidebar:left-60 bg-white pl-4 pr-5 py-2 z-[1010] !shadow-xl hover:ring-gray-300">
      <a
        href="/help/changelog/"
        className="absolute inset-0"
        onClick={() => {
          setIsShown(false)
          setChangelogState((state) => ({
            ...state,
            lastClosed: dayjs().toISOString(),
            lastOpened: dayjs().toISOString(),
          }))
        }}
      ></a>
      <button
        className="absolute top-2 right-3 text-gray-500 hover:text-gray-700"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsShown(false)
          setChangelogState((state) => ({
            ...state,
            lastClosed: dayjs().toISOString(),
          }))
        }}
      >
        <XIcon className="size-4" />
      </button>
      <div>
        <h3 className="text-xs text-gray-600">{t`changelog_fresh_changes`}</h3>
        <p className="text-sm font-medium">{entry?.title}</p>
      </div>
    </Card>
  )
}

export default ChangelogNotifier
