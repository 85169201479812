import {
  CogIcon,
  CollectionIcon,
  DocumentDownloadIcon,
  LogoutIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  SearchIcon,
  ShoppingCartIcon,
  TagIcon,
  ChartPieIcon,
} from '@heroicons/react/outline'
import { Role } from 'lib/dto'
import { Icon } from 'components/icons'
import { HostType } from './theme'
import { MallCode } from './malls'
import { Context } from 'lib/api'

type ConfigSubnavItem = {
  name: string
  active?: string[]
  route: string
  addMall?: boolean
  role?: Role
  malls?: MallCode[]
  hosts?: HostType[]
  customVisibilityCondition?: (ctx: Context) => boolean
}

export type SubnavItem = ConfigSubnavItem & {
  active: string[]
  role: Role
  addMall: boolean
}

type ConfigNavItem<T = ConfigSubnavItem> = T & {
  icon: Icon
  items: T[]
  role: Role
}

export type NavItem = ConfigNavItem<SubnavItem>

const withDefaults = (navbar: ConfigNavItem[]): NavItem[] => {
  for (const item of navbar) {
    item.active ??= []
    item.active.push(item.route)
    item.addMall ??= false
    for (const subitem of item.items) {
      subitem.role ??= item.role
      subitem.hosts ??= item.hosts
      subitem.malls ??= item.malls
      subitem.active ??= []
      item.active.push(subitem.route)
      subitem.active.push(subitem.route)
      subitem.addMall ??= item.addMall
    }
  }

  return navbar as NavItem[]
}

const navbarConfig: ConfigNavItem[] = [
  {
    name: 'pages:business.index',
    icon: ChartPieIcon,
    role: 'ROLE_USER',
    malls: ['wb', 'ozon', 'ozon2'],
    route: '/business/sales/',
    addMall: true,
    items: [
      {
        name: 'sales',
        route: '/business/sales/',
        malls: ['wb', 'ozon', 'ozon2'],
      },
      {
        name: 'orders',
        route: '/business/orders/',
        malls: ['wb', 'ozon', 'ozon2'],
      },
      {
        name: 'reviews',
        route: '/business/reviews/',
        malls: ['wb', 'ozon', 'ozon2'],
      },
      {
        name: 'bidder',
        route: '/business/bidder/',
        malls: ['wb'],
      },
      {
        name: 'repricer',
        route: '/business/repricer/',
        malls: ['wb', 'ozon', 'ozon2'],
      },
      {
        name: 'supply_planning',
        route: '/business/supply-planning/',
        malls: ['wb', 'ozon', 'ozon2'],
      },
      {
        name: 'supply_booking',
        route: '/business/supply-booking/',
        malls: ['wb'],
      },
      {
        name: 'unit-economics',
        route: '/business/unit-economics/',
        malls: ['wb', 'ozon', 'ozon2'],
      },
      {
        name: 'accesses',
        route: '/business/accesses/',
        malls: ['wb', 'ozon', 'ozon2'],
      },
    ],
  },
  {
    name: 'pages:products',
    icon: ShoppingCartIcon,
    role: 'ROLE_USER',
    active: ['/products/info'],
    route: '/products/list/',
    addMall: true,
    items: [
      {
        name: 'product_listing',
        route: '/products/list/',
      },
      {
        name: 'product_comparison',
        route: '/products/comparison/',
      },
    ],
  },
  {
    name: 'pages:categories',
    icon: CollectionIcon,
    role: 'ROLE_USER',
    malls: [
      'wb',
      'noon',
      'noon_saudi',
      'nana',
      'amazon_ae',
      'citilink',
      'lamoda',
      'dns',
      'zdravcity',
      'lavka',
      'vprok',
      'decathlon',
      'talabat',
      'goods',
      'mvideo',
      'goldapple',
      'samokat',
      'magnit_cosmetic',
      'magnit_market',
      'beru',
      'ozon',
      'ozon2',
      'uzum',
    ],
    route: '/categories/list/',
    addMall: true,
    items: [
      {
        name: 'category_listing',
        route: '/categories/list/',
      },
    ],
  },
  {
    name: 'merchants',
    icon: ScaleIcon,
    role: 'ROLE_USER',
    malls: [
      'wb',
      'noon',
      'noon_saudi',
      'nana',
      'amazon_ae',
      'lamoda',
      'lavka',
      'talabat',
      'goods',
      'mvideo',
      'samokat',
      'beru',
      'ozon',
      'ozon2',
      'uzum',
    ],
    route: '/merchants/list/',
    addMall: true,
    items: [{ name: 'merchants_listing', route: '/merchants/list/' }],
  },
  {
    name: 'brands',
    icon: TagIcon,
    role: 'ROLE_USER',
    route: '/brands/content/',
    addMall: true,
    items: [
      {
        name: 'content',
        route: '/brands/content/',
        role: 'ROLE_BRAND',
      },
      {
        name: 'pricing',
        route: '/brands/pricing/',
        role: 'ROLE_BRAND',
      },
      {
        name: 'placement',
        route: '/brands/placement/',
        role: 'ROLE_BRAND',
      },
      {
        name: 'reviews',
        route: '/brands/reviews/',
        role: 'ROLE_BRAND',
      },
      {
        name: 'questions',
        route: '/brands/questions/',
        role: 'ROLE_BRAND',
        malls: ['beru', 'citilink', 'dns', 'lamoda', 'ozon', 'ozon2', 'shopify', 'wb', 'goods'],
      },
      {
        name: 'availability',
        route: '/brands/availability/',
        role: 'ROLE_BRAND',
      },
      {
        name: 'brand_listing',
        route: '/brands/list/',
        malls: [
          'wb',
          'noon',
          'noon_saudi',
          'nana',
          'amazon_ae',
          'citilink',
          'lamoda',
          'dns',
          'zdravcity',
          'lavka',
          'vprok',
          'decathlon',
          'goods',
          'mvideo',
          'goldapple',
          'samokat',
          'beru',
          'ozon',
          'ozon2',
          'uzum',
        ],
      },
    ],
  },
  {
    name: 'search_queries',
    icon: SearchIcon,
    role: 'ROLE_USER',
    route: '/keywords/list/',
    addMall: true,
    items: [
      {
        name: 'search_queries',
        route: '/keywords/list/',
        active: ['/keywords/info'],
        malls: ['wb', 'ozon', 'ozon2'],
      },
      {
        name: 'pages:seo',
        route: '/tools/seo-optimization/',
        malls: ['wb', 'ozon', 'ozon2'],
      },
      {
        name: 'pages:ai_generation',
        route: '/tools/description-generation/',
      },
      {
        name: 'pages:keywords',
        route: '/keywords/my-keywords/',
        addMall: false,
        customVisibilityCondition: (ctx) => {
          return (
            ctx.user.roles.includes('ROLE_BRAND') ||
            (ctx.user.roles.includes('ROLE_USER') && ['wb'].includes(ctx.currentMall.code))
          )
        },
      },
    ],
  },
  {
    name: 'pages:exports',
    icon: DocumentDownloadIcon,
    role: 'ROLE_USER',
    route: '/export/list/',
    items: [
      {
        name: 'exports',
        route: '/export/list/',
      },
    ],
  },
  {
    name: 'help',
    icon: QuestionMarkCircleIcon,
    role: 'ROLE_USER',
    route: '/help/instructions/',
    hosts: ['sellematics', 'sellscreen'],
    items: [
      {
        name: 'instructions',
        route: '/help/instructions/',
      },
      {
        name: 'changelog',
        route: '/help/changelog/',
        hosts: ['sellematics'],
      },
    ],
  },
  {
    name: 'help',
    icon: QuestionMarkCircleIcon,
    role: 'ROLE_USER',
    route: '/help/inner/sales/',
    active: ['/help/'],
    hosts: ['sellmonitor', 'uzum'],
    items: [
      {
        name: 'instructions',
        route: '/help/inner/sales/',
      },
      {
        name: 'changelog',
        route: '/help/changelog/',
        hosts: ['sellmonitor'],
      },
    ],
  },
]

const _additionalRoutes: ConfigNavItem[] = [
  {
    name: 'settings',
    icon: CogIcon,
    role: 'ROLE_USER',
    route: '/settings/user/',
    items: [
      { name: 'user', route: '/settings/user/' },
      { name: 'pages:settings.webauthn', route: '/settings/webauthn/', hosts: ['sellmonitor'] },
      { name: 'password', route: '/settings/password/' },
      { name: 'brands', route: '/settings/brands/', hosts: ['sellematics'] },
      {
        name: 'subscription',
        route: '/settings/subscription/',
      },
    ],
  },
  { name: 'logout', icon: LogoutIcon, role: 'ROLE_USER', route: '/logout/', items: [] },
  {
    name: 'logout',
    icon: LogoutIcon,
    role: 'ROLE_USER',
    route: '/admin/?_switch_user=_exit',
    items: [],
  },
]

export const navbar = withDefaults(navbarConfig)
export const additionalRoutes = withDefaults(_additionalRoutes)
