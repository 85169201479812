import { atomWithImmer } from 'jotai-immer'
import { useSetAtom } from 'jotai'
import { useEffect } from 'react'

export const sellmonitorGptCurrentUserActivityContext = atomWithImmer<
  {
    path: string[]
    relevantData: object
  }[]
>([])

export const useSetUserActivity = (path: string[], relevantData: object) => {
  const setUserActivity = useSetAtom(sellmonitorGptCurrentUserActivityContext)

  useEffect(() => {
    setUserActivity((draft) => {
      const matchingPathPrefixes = draft.filter(
        (activity) => activity.path.every((p, i) => p === path[i]) || activity.path[0] === '/'
      )

      return [...matchingPathPrefixes, { path, relevantData }]
    })

    return () => {
      setUserActivity((draft) => {
        const existing = draft.find((item) => item.path.every((p, i) => p === path[i]))
        if (existing) {
          draft.splice(draft.indexOf(existing), 1)
        }
      })
    }
  }, [path, relevantData])
}
