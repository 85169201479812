import { MallCode } from 'configs/malls'
import { EventCategory } from './type-utils'

export type ISO8601DateString = string

export type MallStat = {
  reviewsCount: number
  productsCount: number
  brandsCount: number
  merchantsCount: number
  categoriesCount: number
  lastObserved: number
}

export type Mall = {
  code: MallCode
  currency: string
  description: string | null
  notification: string | null
  stat: MallStat
}

export type Role = 'ROLE_USER' | 'ROLE_BRAND' | 'ROLE_MERCHANT' | 'ROLE_ADMIN' | 'ROLE_SUPER_ADMIN'

export type User = {
  id: number
  email: string
  fullName: string | null
  active: boolean
  activeUntil: ISO8601DateString | null
  activeMalls: MallCode[]
  roles: Array<Role>
  selectedMall: string
  phone: string
  switched: boolean
  maxMallApiKeys: number
  customColumns: {
    context: string
    column: string
  }[]
  limits: {
    maxMallApiKeys: number
    externalAdsSearches: number
    maxKeywords: number
  }
}

export type ProductStatus = 'deleted' | 'archived' | 'outstocked' | 'available' | 'unknown'

export type Category = {
  url: string | null
  level: number | null
  id: number
  name: string
  actual: boolean
  hasChildren: boolean | null
  pathIds: number[]
  active: boolean
  soldOrdersPercent: number | null
}

export type Status = {
  name: ProductStatus
  ts: ISO8601DateString
  subStatuses: Status[]
}

export type Merchant = {
  id: number
  link: string
  name: string
  products: number | null
  createdAt: ISO8601DateString
  addedAt: ISO8601DateString
  changedAt: ISO8601DateString
  observedAt: ISO8601DateString
  rate: number | null
  inn: string | null
  ogrn: string | null
  url: string | null
}

export type Brand = {
  id: number
  name: string
}

export type Subject = {
  id: number
  name: string
  level: number
  pathIds: number[]
  soldOrdersPercent: number | null
}

export type SubjectTree = Subject & {
  subjects: SubjectTree[]
}

export type ProductTag = {
  id: number
  name: string
}

export type ProductInfo = {
  id: number
  name: string
  mall: string
  mallUrl: string | null
  brand: Brand | null
  mpn: string | null
  merchants: Merchant[]
  images: string[]
  rate: number | null
  lastObserved: ISO8601DateString
  firstObserved: ISO8601DateString
  createdAt: ISO8601DateString
  categories: Category[]
  subjects: Subject[]
  currency: string
  statuses: Status[]
  minPrice: number | null
  maxPrice: number | null
  minMsrp: number | null
  maxMsrp: number | null
  stock: number | null
  reviews: number | null
  reviewsCaptured: number | null
  ratingsCaptured: number | null
  orders: number | null
  inventoryTypes: InventoryType[]
  variations: ProductVariation[]
  variantsCount: number | null
  retailerId: number
  cmmf: string | null
  groupedIds: number[]
  minDiscountPrice: number | null
  maxDiscountPrice: number | null
  characteristics: { name: string; value: string }[]
  tags: ProductTag[]
}

export type FavoriteList = {
  id: number
  name: string
  mall: string
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
}

export type MetricsAggregate = {
  date: ISO8601DateString
  sales: number | null
  gmv: number | null
  reviewsCount: number | null
  reviewsRate: number | null
}

export type MetricsComparison = {
  current: MetricsAggregate | null
  previous: MetricsAggregate | null
}

export const MetricsChartTypes = [
  'gmv',
  'sales',
  'prices',
  'discountPrice',
  'msrp',
  'inventory',
  'rating',
  'review',
  'orders',
  'mallReviews',
  'lostProfit',
] as const

export type MetricsChartType = (typeof MetricsChartTypes)[number]

export const OfferChartTypes = [
  'gmv',
  'sales',
  'prices',
  'msrp',
  'inventory',
  'lostProfit',
] as const

export type OfferChartType = (typeof OfferChartTypes)[number]

export const WarehouseChartTypes = ['gmv', 'sales', 'inventory'] as const

export type WarehouseChartType = (typeof WarehouseChartTypes)[number]

export const VariantChartTypes = ['gmv', 'sales', 'prices', 'inventory'] as const

export type VariantChartType = (typeof VariantChartTypes)[number]

export type ChartDataPoint = {
  date: ISO8601DateString
  value: number
}

export type PriceChartDataPoint = ChartDataPoint & { min: number; max: number }

export type FeedChartDataPoint = ChartDataPoint & { ad: boolean }

export type ContentChartDataPoint = {
  date: ISO8601DateString
  wordsName: number
  wordsDesc: number
  images: number
  videos: number
  characteristics: number
}

export type FeedChartPositionsRequest = {
  feedsIds: number[]
  feedType: keyof typeof FeedTypes
  from?: ISO8601DateString
  to?: ISO8601DateString
}

export type FeedChartPositions = {
  feedId: number
  positions: FeedChartDataPoint[]
}

export type ContentStat = {
  titleWordsCount: number
  descriptionWordsCount: number
  imagesCount: number
  videosCount: number
  pts: number
  characteristicsCount: number
}

export const ContentChartTypes = [
  'title',
  'description',
  'images',
  'videos',
  'characteristics',
] as const

export type ContentChartType = (typeof ContentChartTypes)[number]

export type ContentStats = {
  reference: ContentStat
  product: ContentStat
}

export type FeedType = 'category' | 'store' | 'phrase' | 'brand'

export const FeedTypes = {
  1: 'category',
  2: 'store',
  3: 'phrase',
  4: 'brand',
  6: 'banner',
} as const

export const ReverseFeedTypes = {
  category: 1,
  store: 2,
  phrase: 3,
  brand: 4,
} as const

export type Feed = {
  feedType: keyof typeof FeedTypes
  feedId: number
  entityId: number | null
  productId: number
  feedName: string
  position: number
  archived: ISO8601DateString
  rank: number | null
  searchVolume: number | null
  totalProducts: number | null
  competition: number | null
  feedPath: Category[]
  url: string | null
}

export type ChartData = [Date, number][]
export type FeedChartData = [Date, number, boolean][]

export type ProductVariation = {
  id: number
  title: string
  externalId: string
  price: number | null
  maxPrice: number | null
  msrp: number | null
  maxMsrp: number | null
  imageUrl: string | null
  color: string | null
  size: string | null
  stock: number | null
  deleted: ISO8601DateString | null
  inventoryTypes: InventoryType[]
  warehouses: {
    id: string
    name: string
    stock: number
  }[]
  properties: {
    id: number
    name: string
    value: string
  }[]
}

export type ProductVariationAggregates = {
  variationId: number
  sales: number | null
  gmv: number | null
}

export type Country = {
  code: string
  products: number
  reviews: number
}

export type ProductReviewFilter = {
  rateFrom?: number
  rateTo?: number
  country?: string
  firstAnswerFrom?: ISO8601DateString
  firstAnswerTo?: ISO8601DateString
  createdFrom?: ISO8601DateString
  createdTo?: ISO8601DateString
  answer?: 'all' | 'with_answer' | 'without_answer'
  sort?: `review_${'created' | 'rate' | 'country'}_${'asc' | 'desc'}`
  limit?: number
  after?: number
}

export type ProductReview = {
  id: string
  productId: number
  createdAt: ISO8601DateString
  rate: number | null
  text: string | null
  countryCode: string | null
  answersInfo: {
    count: number
    firstAnswer: ISO8601DateString
    lastAnswer: ISO8601DateString
    allAnswersTimes: ISO8601DateString[]
  } | null
  answers:
    | {
        createdAt: ISO8601DateString
        text: string
      }[]
    | null
}

export type ReviewsByStar = {
  star: string
  reviews: number
}

export type ReviewsByCountry = {
  title: string
  id: string
  count: number
}

export type TimelineEvent = {
  name: 'available' | 'outstocked' | 'archived' | 'deleted' | 'created' | 'added'
  ts: ISO8601DateString
}

export type BrandGroup = {
  name: string
  brandType: 'own' | 'competitor' | 'other'
  mall: string
  brandsIds: number[]
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
}

export type DecryptedBrandIds = {
  name: string
  id: number
}

export type DecryptedBrandGroup = {
  name: string
  brandType: 'own' | 'competitor' | 'other'
  mall: string
  brandsIds: DecryptedBrandIds[]
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
}

export type SubscriptionMall = {
  id: number
  mall: string
  price: number
  nextPrice: number
  renew: boolean
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
}

export type Subscription = {
  id: number
  activeUntil: ISO8601DateString | null
  malls: SubscriptionMall[]
  state: 'created' | 'active' | 'stopped' | 'canceled'
  period: 'month' | '3_months' | '6_months' | 'year'
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
  currency: string
  nextBillAmount: number
  cardMask: string | null
  linkedCard: true
  trial: boolean
}

export type Tariff = {
  mall: MallCode
  name: string
  products: number
  brands: number
  merchants: number
  pricePerMonth: number
  pricePer3Months: number
  pricePer6Months: number
  pricePerYear: number
}

export type SubscriptionTariffs = {
  trial: boolean
  tariffs: Tariff[]
}

export type SubscriptionPeriod = 'month' | '3_months' | '6_months' | 'year'

export type SubscriptionRequest = {
  malls: MallCode[]
  period: SubscriptionPeriod
  promo?: string
}

export type SubscriptionResponse = {
  id: number
  paymentUrl: string
}

export type ExportListItem = {
  id: number
  hidden: boolean
  mall: string
  state: 'waiting' | 'running' | 'complete' | 'failed'
  type: string
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
  totalCount: number
  exportedCount: number
}

export type ExportList = {
  page: number
  pageSize: number
  totalCount: number
  items: ExportListItem[]
}

export type BLArticle = {
  id: number
  name: string
  tag: string
  slug: string
  text: string
  description: string
  active: boolean
  image: {
    original: string
    big: string
  }
  lang: string
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
  imageUrl: string
}

export type BLArticleList = {
  page: number
  pageSize: number
  totalCount: number
  items: BLArticle[]
}

export type MercureMessage = {
  event: string
  data: object
}

export type ExportMercureMessage = {
  event: EventCategory<'export', 'started' | 'finished' | 'error'>
  data: {
    reportId: number
  }
}

export type ProductOfferProperty = {
  id: number
  name: string
  value: string
}

export type InventoryType = 'unknown' | 'marketplace' | 'merchant' | 'express' | 'dbs' | 'retail'

export type ProductOfferInfo = {
  productId: number
  merchantId: number | null
  variationId: number
  name: string
  image: string | null
  inventory: number | null
  price: number | null
  msrp: number | null
  lastObserved: ISO8601DateString
  deleted: boolean
  properties: ProductOfferProperty[]
  inventoryType: InventoryType
}

export type ProductOfferMerchant = {
  merchantId: number
  minPrice: number
  maxPrice: number
  minMsrp: number
  maxMsrp: number
  inventory: number
  merchant: Merchant
  offers: ProductOfferInfo[]
}

export type ProductOfferVariation = {
  variationId: number
  variationName: string
  minPrice: number
  maxPrice: number
  minMsrp: number
  maxMsrp: number
  inventory: number
  offers: ProductOfferInfo[]
}

export type ProductOffers = {
  id: number
  merchants: ProductOfferMerchant[]
  variations: ProductOfferVariation[]
}

export type AutocompleteSuggestion = {
  id: number
  name: string
}

export type CategoryTree = Category & {
  categories: CategoryTree[]
}

export type BusinessAvailableServices =
  | 'innerAnalytics'
  | 'autoreplier'
  | 'bidder'
  | 'repricer'
  | 'supplyBooking'

export type BusinessAvailableApiAccesses =
  | 'content'
  | 'analytics'
  | 'pricesAndDiscounts'
  | 'marketplace'
  | 'statistics'
  | 'promotion'
  | 'questionsAndReviews'
  | 'chatWithCustomers'
  | 'supplies'
  | 'customerReturns'
  | 'documents'

export type BusinessUserApiToken = {
  id: number
  apiToken: string
  availableServices: BusinessAvailableServices[]
  availableApiAccesses: BusinessAvailableApiAccesses[]
  premiumStatus: boolean | null
  readOnly: boolean
  testScopeOnly: boolean
  brokenAt: ISO8601DateString | null
  expiresAt: ISO8601DateString | null
  createdAt: ISO8601DateString | null
}

export type BusinessInnerAnalyticsServiceAccount = {
  id: number
  dataAvailableFrom: ISO8601DateString
  lastSyncedAt: ISO8601DateString | null
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString
  taxRate: number | null
  taxScheme: 'income' | 'incomeMinusExpenses' | null
}

export type BusinessAutoreplierServiceAccount = {
  id: number
  answersCount: number
  enabled: boolean
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString
  lastReplyTime: ISO8601DateString | null
}

export type BusinessBidderServiceAccount = {
  id: number
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString
}

export type BusinessSupplyBookingServiceAccount = {
  id: number
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString
  authorized: boolean
}

export type BusinessUserStore = {
  id: number
  /** User id on BL, same as `User.id` in `Context`. */
  clientExternalId: number[]
  /** Seller id on the marketplace, same as the one users fill when adding a new API key on Ozon, but also present on WB encoded inside the key. */
  marketplaceSellerAccountId: string
  marketplaceCode: MallCode
  needsApiTokenReplacement: boolean
  availableServices: BusinessAvailableServices[]
  hasEmployeeAccessOnMarketplace: boolean
  name: string
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString
  apiTokens: BusinessUserApiToken[]
  innerAnalytics: BusinessInnerAnalyticsServiceAccount | null
  autoreplier: BusinessAutoreplierServiceAccount | null
  bidder: BusinessBidderServiceAccount | null
  supplyBooking: BusinessSupplyBookingServiceAccount | null
}

export type SellersWorkScheme = 'fbo' | 'fbs' | null

export const BusinessSalesStatTypes = ['price', 'percent', 'count'] as const

export type BusinessSalesStatPriceAndCount = {
  price: number | null
  count: number | null
  percent: number | null
}

export type BusinessSalesStatPriceAndPercent = {
  price: number | null
  percent: number | null
  count: number | null
}

export type BusinessSalesCurrentOrPreviousStats = {
  revenueAmount: BusinessSalesStatPriceAndCount
  payedAmount: BusinessSalesStatPriceAndCount
  profitAmount: BusinessSalesStatPriceAndPercent
  netProfitAmount: BusinessSalesStatPriceAndPercent
  orderedAmount: BusinessSalesStatPriceAndCount
  expensesAmount: BusinessSalesStatPriceAndPercent
  primeCostExpenses: BusinessSalesStatPriceAndPercent
  compensationAmount: BusinessSalesStatPriceAndPercent
  commission: BusinessSalesStatPriceAndPercent
  storage: BusinessSalesStatPriceAndPercent
  adExpenses: BusinessSalesStatPriceAndPercent
  fines: BusinessSalesStatPriceAndPercent
  paidAcceptance: BusinessSalesStatPriceAndPercent
  deduction: BusinessSalesStatPriceAndPercent
  logisticToClient: BusinessSalesStatPriceAndCount
  logisticFromClient: BusinessSalesStatPriceAndCount
  tax: BusinessSalesStatPriceAndPercent
  additionalExpenses: BusinessSalesStatPriceAndPercent
  returnedAmount: BusinessSalesStatPriceAndCount
  stock: BusinessSalesStatPriceAndCount
  stockFull: BusinessSalesStatPriceAndCount
  stockToClient: BusinessSalesStatPriceAndCount
  stockFromClient: BusinessSalesStatPriceAndCount
  stockOnWay: BusinessSalesStatPriceAndCount
  cancelledAmount: BusinessSalesStatPriceAndCount
  additionalService: BusinessSalesStatPriceAndPercent
  cancelledAmountFromOrders: BusinessSalesStatPriceAndPercent
}

export type CommonOrdersStat = {
  period: {
    from: ISO8601DateString
    to: ISO8601DateString
  }
  stat: {
    orders: BusinessSalesStatPriceAndCount
    soldOrders: BusinessSalesStatPriceAndCount
    returnedOrders: BusinessSalesStatPriceAndCount
    cancelledOrders: BusinessSalesStatPriceAndCount
    deliveringOrders: BusinessSalesStatPriceAndCount
  }
}

export type BusinessSalesStats = {
  current: BusinessSalesCurrentOrPreviousStats
  previous: BusinessSalesCurrentOrPreviousStats
  commonOrdersStat: CommonOrdersStat
}

export type BusinessSalesProductStats = {
  price: number | null
  count: number | null
  percent: number | null
}

export type BusinessSalesProductSort = ListSort<
  | 'name'
  | 'rating'
  | 'reviewsCount'
  | 'orderedAmountCount'
  | 'orderedAmountPrice'
  | 'payedAmountPrice'
  | 'payedAmountCount'
  | 'returnedAmountCount'
  | 'returnedAmountPrice'
  | 'revenueAmountPrice'
  | 'profitAmountPrice'
  | 'expensesAmountPrice'
  | 'logisticPrice'
  | 'commissionPrice'
  | 'compensationPrice'
  | 'adPrice'
  | 'storagePrice'
  | 'finesPrice'
  | 'paidAcceptancePrice'
  | 'deductionPrice'
  | 'otherExpensesPrice'
  | 'primeCostExpensesPrice'
  | 'primeCostPrice'
  | 'stockCount'
  | 'stockOnWayCount'
  | 'returnOnInvestmentPercent'
  | 'marginalityPercent'
  | 'soldOrdersPercent'
>

export type BusinessSalesProductListFilter = {
  from: string
  to: string
  after: number
  limit: number
  brandId: number[]
  article: string[]
  name?: string
  search?: string[]
  sort?: BusinessSalesProductSort
  revenueAbcGroup?: string
  profitAbcGroup?: string
  marketplaceAccountId?: number
  sellersWorkScheme?: SellersWorkScheme
}

export type BusinessSalesProductRowStats = {
  orderedAmount: BusinessSalesProductStats
  payedAmount: BusinessSalesProductStats
  returnedAmount: BusinessSalesProductStats
  expensesAmount: BusinessSalesProductStats
  profitAmount: BusinessSalesProductStats
  primeCostExpenses: BusinessSalesProductStats
  commission: BusinessSalesProductStats
  compensationAmount: BusinessSalesProductStats
  storage: BusinessSalesProductStats
  logistic: BusinessSalesProductStats
  deliveryLogistic: BusinessSalesProductStats
  returnLogistic: BusinessSalesProductStats
  deliveryUnitLogisticPrice: number | null
  returnUnitLogisticPrice: number | null
  ad: BusinessSalesProductStats
  fines: BusinessSalesProductStats
  paidAcceptance: BusinessSalesProductStats
  deduction: BusinessSalesProductStats
  otherExpenses: BusinessSalesProductStats
  tax: BusinessSalesProductStats
  additionalExpenses: BusinessSalesProductStats
  stock: BusinessSalesProductStats
  stockToClient: BusinessSalesProductStats
  stockFromClient: BusinessSalesProductStats
  stockOnWay: BusinessSalesProductStats
  supplyRecommendation: {
    count: number
    daysSalesOfInventory: number
  }
  profitAbcAnalysis: {
    group: string
    percent: number
  }
  revenueAbcAnalysis: {
    group: string
    percent: number
  }
  commonOrdersStat: CommonOrdersStat
  returnOnInvestmentPercent: number | null
  profitabilityPercent: number | null
  marginalityPercent: number | null
}

export type BusinessSalesProductSize = BusinessSalesProductRowStats & {
  article: string
  size: string
  barcodes: string[]
  primeCost: number | null
}

export type BusinessSalesProduct = BusinessSalesProductRowStats & {
  id: number
  removed: boolean
  name: string
  brand: string
  article: string
  supplierArticle: string
  image: string
  url: string
  rating: number
  reviewsCount: number
  barcodes: string[]
  primeCost: { start: number; end: number } | null
  sizes: BusinessSalesProductSize[]
}

export type BusinessSalesTableRow = BusinessSalesProduct | BusinessSalesProductSize

export type AbcGroupSizes = { a: number; b: number; c: number; d: number; u: number }

export type BusinessSalesProductList = {
  products: BusinessSalesProduct[]
  total: number
  profitAbcGroupSizes: AbcGroupSizes
  revenueAbcGroupSizes: AbcGroupSizes
}

export type BusinessSalesChartData = {
  ts: ISO8601DateString
  ordersPrice: number | null
  ordersCount: number | null
  revenuePrice: number | null
  salesPrice: number | null
  salesCount: number | null
  returnsPrice: number | null
  returnsCount: number | null
  cancelsPrice: number | null
  cancelsCount: number | null
  primeCostExpensesPrice: number | null
  deliveryLogisticPrice: number | null
  returnLogisticPrice: number | null
  commissionPrice: number | null
  compensationPrice: number | null
  storagePrice: number | null
  finesPrice: number | null
  paidAcceptancePrice: number | null
  deductionPrice: number | null
  adExpensesPrice: number | null
  otherExpensesPrice: number | null
  expensesPrice: number | null
  profitPrice: number | null
  taxPrice: number | null
  additionalExpensesPrice: number | null
}

export type BusinessSalesProductChartPoints = {
  ts: ISO8601DateString[]
  ordersPrice: number[]
  ordersCount: number[]
  salesPrice: number[]
  salesCount: number[]
  returnsPrice: number[]
  returnsCount: number[]
  revenuePrice: number[]
  expensesPrice: number[]
  profitPrice: number[]
  compensationPrice: number[]
  commissionPrice: number[]
  storagePrice: number[]
  finesPrice: number[]
  paidAcceptancePrice: number[]
  deductionPrice: number[]
  otherExpensesPrice: number[]
  logisticPrice: number[]
  deliveryLogisticPrice: number[]
  returnLogisticPrice: number[]
  adExpensesPrice: number[]
  availableStockCount: number[]
  primeCostExpensesPrice: number[]
  returnOnInvestmentPercent: number[]
  marginalityPercent: number[]
  soldOrdersPercent: number[]
}

export type BusinessSalesBrand = {
  id: number
  name: string
}

export type BusinessSalesData = {
  apiKeys: BusinessUserStore[]
  apiKeysLoading: boolean
  stats: BusinessSalesStats | null
  statsLoading: boolean
  statsChartData: BusinessSalesChartData[] | null
  startsChartDataLoading: boolean
  products: BusinessSalesProduct[]
  productsTotalCount: number
  profitAbcGroupSizes: AbcGroupSizes
  revenueAbcGroupSizes: AbcGroupSizes
  productsLoading: boolean
  brands: BusinessSalesBrand[]
  brandsLoading: boolean
  hasNullPrimeCosts: boolean
  primeCostsHaveBeenChanged: boolean
  sellersWorkScheme: SellersWorkScheme
}

export type BusinessSalesPeriodFilter = {
  periodType: string
  dateFrom: string
  dateTo: string
}

export type BusinessSalesProductFilter = {
  productsAfter: number
  productsLimit: number
  brands: number[]
  articles: string[]
  name?: string
  search?: string[]
  sort?: BusinessSalesProductSort
  revenueAbcGroup?: AbcGroup
  profitAbcGroup?: AbcGroup
}

export type BusinessSalesProductChartRequest = {
  from: ISO8601DateString | null
  to: ISO8601DateString | null
  skus: {
    sku: string
    sizes: string[]
  }[]
  charts: (keyof BusinessSalesChartData)[]
}

export type BusinessSalesProductChartData = {
  sku: string
  size: string
  points: BusinessSalesProductChartPoints
}[]

export type BusinessSalesExpenseCreateForm = {
  amount: number
  name: string
  marketplaceAccountIds: number[]
  date: ISO8601DateString
  sku: string | null
  size: string | null
  note: string | null
}

export type BusinessSalesExpense = BusinessSalesExpenseCreateForm & {
  id: number
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString
  product: {
    sku: string
    name: string
    image: string
    url: string
  }
}

export type BusinessSalesExpenseFilter = {
  marketplaceAccountIds?: number[]
  from?: ISO8601DateString
  to?: ISO8601DateString
  search?: string | null
}

export type BusinessSalesExpenseList = {
  summary: {
    totalCost: number
    totalCostByNames:
      | {
          name: string
          cost: number
        }[]
      | null
  }
  items: BusinessSalesExpense[]
}

export type BusinessSalesExpenseNameList = {
  names: string[]
}

export type BusinessOrdersData = {
  apiKeys: BusinessUserStore[]
  apiKeysLoading: boolean
  brands: BusinessSalesBrand[]
  brandsLoading: boolean
}

export type BusinessOrderStatus = 'on_delivery' | 'cancelled' | 'purchased' | 'returned'

export type BusinessPostingStatus =
  | 'acceptance_in_progress'
  | 'arbitration'
  | 'awaiting_approve'
  | 'awaiting_deliver'
  | 'awaiting_packaging'
  | 'awaiting_registration'
  | 'awaiting_verification'
  | 'cancelled'
  | 'cancelled_from_split_pending'
  | 'client_arbitration'
  | 'delivering'
  | 'driver_pickup'
  | 'not_accepted'
  | 'sent_by_seller'
  | 'delivered'

export type BusinessOrdersListFilter = {
  marketplaceAccountId?: number
  from?: ISO8601DateString
  to?: ISO8601DateString
  brandsIds?: number[]
  sellersWorkScheme?: SellersWorkScheme
  orderStatus?: BusinessOrderStatus
  postingStatus?: BusinessPostingStatus
  article?: string[]
  after?: number
  limit?: number
}

export type BusinessOrderProduct = {
  id: number
  name: string
  size: string | null
  brand: string
  article: string
  supplierArticle: string
  image: string
  url: string
  rating: number
  reviewsCount: number
  primeCost: number | null
  barcode: string[]
  orderedPrice: number
  soldPrice: number
  returnedPrice: number
  cancelledPrice: number
  orderedQuantity: number
  soldQuantity: number
  returnedQuantity: number
  cancelledQuantity: number
  primeCostExpenses: number | null
  logisticPrice: number | null
  commissionPrice: number | null
  expensesPrice: number | null
  profitPrice: number | null
  returnOnInvestmentPercent: number | null
  marginalityPercent: number | null
}

export type BusinessOrdersListItem = {
  externalId: string
  orderStatus: BusinessOrderStatus
  postingStatus: BusinessPostingStatus
  orderedAt: ISO8601DateString | null
  cancelledAt: ISO8601DateString | null
  soldAt: ISO8601DateString | null
  returnedAt: ISO8601DateString | null
  products: BusinessOrderProduct[]
  orderedPrice: number
  soldPrice: number
  returnedPrice: number
  cancelledPrice: number
  warehouseId: number
  warehouseName: string
  regionId: number
  regionName: string
  primeCostExpenses: number | null
  logisticPrice: number | null
  commissionPrice: number | null
  expensesPrice: number | null
  profitPrice: number | null
  returnOnInvestmentPercent: number | null
  marginalityPercent: number | null
}

export type BusinessReviewsStopword = {
  id: number
  text: string
  marketplaceAccountId: number
  updatedAt: ISO8601DateString | null
}

export type BusinessReviewsTemplate = {
  id: number
  title: string
  text: string
  marketplaceAccountId: number
  brandNames: string[]
  productsId: string[]
  rulesId: number[]
  answersCount: number
  updatedAt: ISO8601DateString | null
}

export type BusinessReviewsRule = {
  id: number
  title: string
  marketplaceAccountId: number
  brandNames: string[]
  productsId: string[]
  templatesId: number[]
  accountableRate: number[]
  enabled: boolean
  updatedAt: ISO8601DateString | null
  answersCount: number
  replyToNonEmptyReviews?: boolean
  replyToEmptyReviews?: boolean
}

export type BusinessReviewsBrandList = {
  id: number
  list: BusinessSalesBrand[] | null
  loading: boolean
}

export type BusinessProductNoStats = {
  id: number
  name: string | null
  brand: string
  article: string
  image: string
  url: string
  rating: number
  reviewsCount: number
}

export type BusinessReviewsProductList = {
  id: number
  list: BusinessProductNoStats[] | null
  loading: boolean
}

export type BusinessReviewsData = {
  apiKeys: BusinessUserStore[]
  apiKeysLoading: boolean
  accesses: BusinessUserStore[]
  stopwords: BusinessReviewsStopword[]
  stopwordsLoading: boolean
  templates: BusinessReviewsTemplate[]
  templatesLoading: boolean
  rules: BusinessReviewsRule[]
  rulesLoading: boolean
  products: BusinessReviewsProductList[]
  brands: BusinessReviewsBrandList[]
}

export type BusinessReviewsOnboarding = {
  accesses: boolean
  stopwords: boolean
  templates: boolean
  rules: boolean
}

export type BusinessReviewsApiKeyStat = {
  id: number
  answersCount: number | null
  loading: boolean
}

export type BusinessReviewsStopwordFilter = {
  marketplaceAccountId?: number
  text?: string
}

export type BusinessReviewsTemplateFilter = {
  marketplaceAccountId?: number
  title?: string
  productsId?: string[]
  brandNames?: string[]
}

export type BusinessReviewsRuleFilter = {
  marketplaceAccountId?: number
  title?: string
  productId?: string
  brandName?: string
  templateId?: number
  enabled?: boolean
}

export type BusinessReviewsDefaultSort = {
  key: string
  order: string
}

export const BusinessReviewsFormTypeValue = ['all', 'brands', 'products'] as const

export type BusinessReviewsPagination = {
  activePage: number
  pageSize: number
}

export type BidderPageData = {
  apiKeys: BusinessUserStore[]
  accountsWithAdsKey: BusinessUserStore[]
  selectedApiKey: BusinessUserStore | null
}

export type Interval = {
  start: number
  end: number
}

export type DayOfWeek = 'monday'

export type BidderCampaignType = 'auto' | 'catalog_and_search'

export type BidderCampaignStatus = 'ready_to_start' | 'ended' | 'running' | 'on_pause' | 'refused'

export type BidderStatus =
  | 'disabled'
  | 'runningForBidManagement'
  | 'runningForBidChecking'
  | 'runningWithFixedBidPrice'
  | 'waitingForParamsCorrection'
  | 'waitingForScheduleConditions'
  | 'waitingForBidPriceConditions'
  | 'waitingForBudgetDailyLimitReset'

export type BidderCampaignListItem = {
  id: number
  externalId: string
  marketplaceAccountId: number
  name: string
  type: BidderCampaignType
  status: BidderCampaignStatus
  budget: number
  dailyBudget: number
  bidPrice: number | null
  maxBidPrice: number | null
  note: string | null
  sendNotifications: boolean
  bidderEnabled: boolean
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString | null
  startedAt: ISO8601DateString | null
  endedAt: ISO8601DateString | null
  bidderLastWorkedAt: ISO8601DateString | null
  viewsCount: number
  clicksCount: number
  cartAdditionsCount: number
  ordersCount: number
  orderedProductsCount: number
  totalOrdersPrice: number
  totalAdExpensesPrice: number
  clickThroughRate: number
  costPerClick: number
  conversionRate: number
  adExpensesPercent: number | null
  bidderStatus: BidderStatus
  stoppingReason:
    | 'UnsupportedCampaignType'
    | 'InappropriateCampaignStatus'
    | 'InappropriateProductsCount'
    | 'InappropriateActivePhrasesCount'
    | 'InappropriateEnabledDisplayZones'
    | 'ZeroBudget'
    | 'MaxBidPriceIsBelowMinAllowable'
    | 'FixedBidPriceIsBelowMinAllowable'
    | 'MissingMaxBidPrice'
    | 'MissingTargetPositionInterval'
    | 'NoActiveTimeIntervalsInActivitySchedule'
    | 'BidPriceExceededMaxBidPrice'
    | 'MissingActiveClusters'
    | 'MissingMasterClusters'
    | 'ExceededMasterClustersCount'
    | 'ExceededDailyBudgetLimit'
    | 'StoppedByActivitySchedule'
    | null
}

export type DisplayZones = {
  productCard: boolean
  searchOrCatalog: boolean
}

export type ManagementType = 'masterClusters' | 'masterAndActiveClusters' | 'allClusters'

export type FallbackBidStrategy = 'stopCampaign' | 'setFixedBid'

export type TargetPositionCluster = 'best' | 'worst'

export type BidderRegion = {
  id: string
  name: string
  address: string
}

export type BidderSyncResponse = {
  lastSyncedAt: ISO8601DateString
}

export type BidderCampaignProduct = {
  sku: string
  deliveryTimeInHours: number
  name: string | null
  image: string | null
  brandName: string | null
  merchantName: string | null
  price: number | null
  msrp: number | null
  sales: number | null
  gmv: number | null
  rating: number | null
  reviewsCount: number | null
}

export type BidderCampaignInfo = BidderCampaignListItem & {
  subjectName: string | null
  setName: string | null
  menuName: string | null
  displayZones: DisplayZones | null
  bidIncreaseValue: number | null
  targetPositionInterval: Interval | null
  fallbackBidStrategy: FallbackBidStrategy
  fixedBidPrice: number
  minBidPrice: number
  targetPositionCluster: TargetPositionCluster
  activitySchedule: boolean[][]
  region: BidderRegion
  lastBidderStat: BidderCampaignBidderStat | null
  managementType: ManagementType
  products: BidderCampaignProduct[] | null
  lastSyncedAt: ISO8601DateString | null
  keywordsLastSyncedAt: ISO8601DateString | null
  statLastSyncedAt: ISO8601DateString | null
  runningCampaignsWithSameProduct: {
    id: number
    externalId: string
    name: string
  }[]
}

export type BidderCampaignUpdateInfo = Partial<{
  name: string
  note: string
  displayZones: DisplayZones
  fallbackBidStrategy: FallbackBidStrategy
  fixedBidPrice: number
  targetPositionCluster: TargetPositionCluster
  maxBidPrice: number
  bidIncreaseValue: number
  targetPositionInterval: Interval
  bidderEnabled: boolean
  activitySchedule: boolean[][]
  managementType: ManagementType | null
  regionId: string | null
  productSkus: string[]
}>

export type BidderCampaignListFilter = {
  marketplaceAccountId?: number
  name?: string
  type?: BidderCampaignType
  status?: BidderCampaignStatus[]
  sort?: ListSort<
    | 'name'
    | 'bidPrice'
    | 'createdAt'
    | 'bidderEnabled'
    | 'totalAdExpensesPrice'
    | 'clickThroughRate'
  >
  statFrom?: ISO8601DateString
  statTo?: ISO8601DateString
}

export type AbcGroup = 'A' | 'B' | 'C' | 'D' | 'U'

export type BidderKeyword = {
  name: string
  searchVolume: number | null
}

export type BidderCluster = {
  id: number
  name: string
  searchVolume: number | null
  activeStatus: 'master' | 'active' | 'minus'
  keywords: BidderKeyword[]
  viewsCount: number | null
  clicksCount: number | null
  adExpensesPrice: number | null
  clickThroughRate: number | null
  costPerClick: number | null
  abcGroup: AbcGroup
  abcPercent: number
}

export type BidderCampaignKeywords = {
  clusters: BidderCluster[]
  nonClusterKeywords: BidderKeyword[] | null
}

export type BidderCampaignKeywordsUpdate = {
  activeClusterIds: number[]
  masterClusterIds: number[]
}

export type BidderClusterMetrics = {
  clusterName: string
  viewsCount: number | null
  position: number | null
  organicPosition: number | null
}

export type BidderCampaignStat = {
  ts: ISO8601DateString
  clusters: BidderClusterMetrics[] | null
  viewsCount: number
  clicksCount: number
  uniqueUsersCount: number
  cartAdditionsCount: number
  ordersCount: number
  orderedProductsCount: number
  totalOrdersPrice: number
  totalAdExpensesPrice: number
  viewsToUniqueUsersRatio: number
  clickThroughRate: number
  costPerClick: number
  conversionRate: number
  position: number | null
  organicPosition: number | null
  bidPrice: number | null
}

export type BidderCampaignStatSummary = {
  viewsCount: number
  clicksCount: number
  uniqueUsersCount: number
  cartAdditionsCount: number
  ordersCount: number
  orderedProductsCount: number
  totalOrdersPrice: number
  totalAdExpensesPrice: number
  viewsToUniqueUsersRatio: number
  clickThroughRate: number
  costPerClick: number
  conversionRate: number
  adExpensesPercent: number | null
  avgBidPrice: number | null
  avgPosition: number | null
  avgOrganicPosition: number | null
}

export type BidderCampaignBidderStat = {
  ts: ISO8601DateString
  clusters: BidderClusterMetrics[]
  bidPrice: number | null
  startTargetPosition: number | null
  endTargetPosition: number | null
  maxBidPrice: number | null
  fallbackBidStrategy: FallbackBidStrategy
  fixedBidPrice: number
  targetPositionCluster: TargetPositionCluster
  bidderStatus: BidderStatus
}

export type BidderCampaignBidDistributionRequest = {
  feedId: string
  feedType: string
  regionId: string
  adType?: BidderCampaignType
}

export type BidderCampaignBidDistribution = {
  sku: string
  deliveryTimeInHours: number | null
  page: number
  position: number
  organicPosition: number
  bidPrice: number | null
  belongsToCampaign: boolean
  externalAdCampaignId: string | null
  name: string | null
  image: string | null
  url: string | null
  brandName: string | null
  merchantName: string | null
  price: number | null
  msrp: number | null
  sales: number | null
  gmv: number | null
  rating: number | null
  reviewsCount: number | null
  adType: BidderCampaignType | null
}

export type BidderCampaignBidPrediction = {
  position: number
  bidPrice: number
}

export type BidderCampaignHeatmapFilter = {
  marketplaceAccountId?: number
  campaignId?: number
  from?: ISO8601DateString
  to?: ISO8601DateString
}

export type BidderCampaignHeatmapValue = {
  viewsCount: number
  clicksCount: number
  ordersCount: number
  viewToClickRate: number
  clickToOrderRate: number
  viewToOrderRate: number
  costPerView: number
  costPerClick: number
  costPerOrder: number
}

export type BidderCampaignHeatmap = {
  dataByWeekDaysAndHours: {
    values: BidderCampaignHeatmapValue[][]
    minDeviationPercent: BidderCampaignHeatmapValue
    maxDeviationPercent: BidderCampaignHeatmapValue
    avgValue: BidderCampaignHeatmapValue
  }
  dataByWeekDays: {
    values: BidderCampaignHeatmapValue[]
    minDeviationPercent: BidderCampaignHeatmapValue
    maxDeviationPercent: BidderCampaignHeatmapValue
    avgValue: BidderCampaignHeatmapValue
  }
  dataByHours: {
    values: BidderCampaignHeatmapValue[]
    minDeviationPercent: BidderCampaignHeatmapValue
    maxDeviationPercent: BidderCampaignHeatmapValue
    avgValue: BidderCampaignHeatmapValue
  }
}

export type BidderCampaignBudgetReplenishment = {
  budget: number
  balance: {
    balance: number
    net: number | null
  }
  replenishmentEnabled: boolean
  replenishmentSource: 'balance' | 'net'
  dailyBudgetLimitEnabled: boolean
  dailyBudgetLimit: number | null
  currentDailyExpenses: number
  lastLog: {
    createdAt: ISO8601DateString
    replenishmentStatus:
      | 'NoReplenishmentRequired'
      | 'ImpossibleDueToExceedingDailyLimit'
      | 'ImpossibleDueToInsufficientBalance'
      | 'BudgetReplenished'
  } | null
}

export type BidderCampaignBudgetReplenishmentUpdate = {
  replenishmentEnabled: boolean
  replenishmentSource: 'balance' | 'net'
  dailyBudgetLimitEnabled: boolean
  dailyBudgetLimit: number | null
}

export type BidderCampaignBudgetReplenishmentLog = {
  createdAt: ISO8601DateString
  replenishmentSource: 'balance' | 'net'
  dailyBudgetLimitEnabled: boolean
  dailyBudgetLimit: number | null
  currentBudget: number
  currentReplenishmentAmount: number
  currentDailyExpenses: number
  replenishmentStatus:
    | 'NoReplenishmentRequired'
    | 'ImpossibleDueToExceedingDailyLimit'
    | 'ImpossibleDueToInsufficientBalance'
    | 'BudgetReplenished'
}

export type BidderProductCurrentPosition = {
  adPosition: string | null
  organicPosition: string | null
  bidPrice: number | null
}

export type SupplyPlanningPageData = {
  apiKeys: BusinessUserStore[]
  currentSettings: SupplyRecommendationSettings
  availableSettings: SupplyRecommendationAvailableSettings
  currentReport: SupplyRecommendationReport | null
}

export type SupplyRecommendationSettings = {
  marketplaceAccountId?: number
  periodType: 'interval' | 'lastDays'
  from: ISO8601DateString
  to: ISO8601DateString
  lastDaysCount: number
  supplyDate: ISO8601DateString | null
  warehouseLoadingDays: number
  supplyCorrectionPercent: number
  calcType: SupplyRecommendationType
  warehouseIds: number[] | null
  supplyRegionIds: number[] | null
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString
}

export type SupplyRecommendationAvailableSettings = {
  warehouses: { id: number; name: string }[]
  supplyRegions: { id: number; name: string; warehouses: { id: number; name: string }[] }[]
}

export type SupplyRecommendationType = 'simple' | 'byWarehouses' | 'byRegions'

export type SupplyRecommendationRequest = {
  marketplaceAccountId?: number
  supplyDate?: ISO8601DateString | null
  from: ISO8601DateString
  to: ISO8601DateString
  lastDaysCount: number
  warehouseLoadingDays: number
  supplyCorrectionPercent: number
  calcType: SupplyRecommendationType
  warehouseIds?: number[] | null
  supplyRegiodIds?: number[] | null
}

export type SupplyRecommendationRow =
  | SupplyRecommendationProduct
  | SupplyRecommendationSize
  | SupplyRecommendationWarehouse
  | SupplyRecommendationRegion

export type SupplyRecommendationCalculations = {
  availableStocksCount: number
  deliveringStocksCount: number
  returningStocksCount: number
  salesCount: number
  salesPercent: number
  returnsCount: number
  cancelsCount: number
  salesPerDay: number
  returnsPerDay: number
  cancelsPerDay: number
  returnDeliveryDuration: number | null
  turnoverDays: number
  stocksOnSupplyDate: number | null
  salesOnSupplyDate: number | null
  stocksForWarehouseLoading: number
  stocksEndAt: number | null
  needSupplyUnits: number
  boxMultiplicity: number
  needSupplyBoxes: number
  supplyPrice: number | null
}

export type SupplyRecommendationWarehouse = SupplyRecommendationCalculations & {
  warehouseId: number
  warehouseName: string
}

export type SupplyRecommendationRegion = SupplyRecommendationCalculations & {
  supplyRegionId: number
  supplyRegionName: string
  supplyRegions?: SupplyRecommendationRegion[]
}

export type SupplyRecommendationSize = SupplyRecommendationCalculations & {
  size: string
  barcodes: string[]
  supplyRegions: SupplyRecommendationRegion[] | null
  warehouses: SupplyRecommendationWarehouse[] | null
}

export type SupplyRecommendationProduct = SupplyRecommendationCalculations & {
  id: number | null
  name: string | null
  marketplaceAccountId: number
  brand: string | null
  sku: string
  supplierSku: string
  image: string | null
  url: string
  rating: number | null
  primeCost: number | null
  sizes: SupplyRecommendationSize[]
}

export type SupplyRecommendationSummary = {
  needSupplyUnits: number
  supplyPrice: number | null
  availableProducts: number | null
  outOfStockProducts: number | null
  willBeOutOfStockProducts: number | null
  needSupplyProducts: number | null
  warehouses: {
    warehouseId: number
    warehouseName: string
    availableStocksCount: number
    ordersCount: number
    salesCount: number
    needSupplyUnits: number
  }[]
  supplyRegions: {
    supplyRegionId: number
    supplyRegionName: string
    availableStocksCount: number
    ordersCount: number
    salesCount: number
    needSupplyUnits: number
  }[]
}

export type SupplyRecommendationReport = {
  summary: SupplyRecommendationSummary
  products: SupplyRecommendationProduct[]
}

export type SupplyBookingOrder = {
  id: number
  marketplaceAccountId: number
  orderId: string
  supplyId: string
  boxTypeName: string
  warehouseName: string
  totalQuantity: number
  acceptanceCoefficient: number
  price: number
  plannedDate: ISO8601DateString
  status:
    | 'notScheduled'
    | 'scheduled'
    | 'allowShipping'
    | 'acceptanceInProgress'
    | 'accepted'
    | 'virtual'
    | 'shippedOnGate'
  bookingStatus: 'off' | 'scheduled' | 'completed'
  products: {
    photo: string
    barcode: string
    subjectName: string
    colorName: string
    sellerArticle: string
    brandName: string
    quantity: number
    nmId: string
    fullName: string
  }[]
  dateFrom: ISO8601DateString
  dateTo: ISO8601DateString
  maxCoefficient: number
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString
}

export type SupplyBookingOrderPlanRequest = {
  dateFrom: ISO8601DateString
  dateTo: ISO8601DateString
  maxCoefficient: number
}

export type RepricerScenario =
  | {
      type: 'competitors'
      enabled: boolean
      options: {
        priceStrategy: 'min' | 'max' | 'avg'
        competitorsProducts: {
          sku: string
          name: string
          url: string
          image: string
        }[]
        competitorsRequiredStock?: number
        offset: number
      }
    }
  | {
      type: 'schedule'
      enabled: boolean
      options: {
        /** 7*24 array of prices corresponding to Mon–Sun 00-23 */
        schedule: (number | null)[][]
      }
    }
  | {
      type: 'stock'
      enabled: boolean
      options: {
        units: { stock: number; price: number }[]
      }
    }

export type RepricerBeautifulPrice = {
  enabled: boolean
  beautifyStrategy: 'up' | 'down' | 'nearest'
  template: string
  maxDifference: number
}

export type RepricerConfig = {
  minPrice: number
  maxPrice: number
  defaultPrice: number
  changeFactor: 'discount' | 'msrp'
  scenarios: RepricerScenario[]
  products: RepricerProduct[]
  beautifulPrice: RepricerBeautifulPrice
}

export type RepricerProduct = {
  sku: string
  name: string
  url: string
  image: string
  lastChange: {
    timestamp: ISO8601DateString
    oldMsrp: number
    newMsrp: number
    oldPrice: number
    newPrice: number
    oldSpp: number | null
    newSpp: number | null
  } | null
}

export type RepricerAddRequest = {
  marketplaceAccountId: number
  name: string
  config: RepricerConfig
}

export type RepricerUpdateRequest = {
  name: string
  config: RepricerConfig
}

export type RepricerListFilter = {
  marketplaceAccountId?: number
  limit?: number
  after?: number
  name?: string
  enabled?: boolean
  skus?: string[]
}

export type RepricerListItem = {
  id: number
  marketplaceAccountId: number
  name: string
  enabled: boolean
  config: RepricerConfig
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString
}

export type RepricerGetProductsInfoRequest = {
  skus: string[]
}

export type RepricerProductInfo = {
  sku: string
  name: string
  url: string
  image: string
  minMsrp: number
  avgMsrp: number
  maxMsrp: number
  minPrice: number
  avgPrice: number
  maxPrice: number
  stock: number
}

export type RepricerGetChangesRequest = {
  from: ISO8601DateString
  to: ISO8601DateString
}

export type RepricerChartData = {
  chartsMapKeys: string[]
  chartsMap: Record<
    string,
    ({
      msrp: number
      price: number
      discountPercent: number
      spp: number | null
      sppPercent: number | null
      beautifulPrice: RepricerBeautifulPrice | null
      stock: number
      timestamp: ISO8601DateString
      changeFactor: 'discount' | 'msrp'
    } & (
      | {
          changeReason: 'competitors'
          changeInfo: {
            priceStrategy: 'min' | 'max' | 'avg'
            price: number
            offset: number
            sku: string | null
            stock: number | null
          }
        }
      | { changeReason: 'schedule'; changeInfo: { schedulePrice: number } }
      | { changeReason: 'stock'; changeInfo: { stockBound: number; requiredPrice: number } }
      | { changeReason: 'default'; changeInfo: { defaultPrice: number } }
    ))[]
  >
  productsMap: Record<string, { sku: string; name: string; url: string; image: string }>
}

export type RepricerChangelog = {
  changelog: ({
    sku: string
    oldMsrp: number
    newMsrp: number
    oldPrice: number
    newPrice: number
    oldDiscountPercent: number
    newDiscountPercent: number
    oldSpp: number | null
    newSpp: number | null
    oldSppPercent: number | null
    newSppPercent: number | null
    beautifulPrice: RepricerBeautifulPrice | null
    stock: number
    timestamp: ISO8601DateString
    changeFactor: 'discount' | 'msrp'
  } & (
    | {
        changeReason: 'competitors'
        changeInfo: {
          priceStrategy: 'min' | 'max' | 'avg'
          price: number
          offset: number
          sku: string | null
          stock: number | null
        }
      }
    | { changeReason: 'schedule'; changeInfo: { schedulePrice: number } }
    | { changeReason: 'stock'; changeInfo: { stockBound: number; requiredPrice: number } }
    | { changeReason: 'default'; changeInfo: { defaultPrice: number } }
  ))[]
  productsMap: Record<string, { sku: string; name: string; url: string; image: string }>
}

export type BusinessUnitEconomicsCalculations = {
  price: number
  fullPrice: number
  discount: number
  discountAmount: number
  discountPrice: number
  maxDiscountBeforeZeroProfit: number
  quantity: number
  priceOfDelivery: number
  commissionPercent: number
  commissionAmount: number
  processingOfGoods: number
  promotionExpensesPercent: number
  promotionExpensesAmount: number
  additionalExpensesPerUnit: number
  additionalExpenses: number
  storing: number
  conversion: number
  expectedReverseLogisticsCost: number
  delivery: number
  processing: number
  logistics: number
  lastMile: number
  grossProfit: number
  taxPercent: number
  taxScheme: 'revenue' | 'profit'
  taxAmount: number
  profitPerGoodPercent: number
  profitPerGoodAmount: number
  profitPerGoodsAmount: number
  revenuePerShipment: number
  length: number
  width: number
  height: number
  volume: number
}

export type MarketplaceCommission = {
  id: number
  marketplaceCode: MallCode
  category: string
  subject: string
  marketplaceWarehousePercent: number
  supplierWarehouseToMarketplacePercent: number
  supplierWarehouseSelfPercent: number
  supplierWarehouseSelfExpressPercent: number
  createdAt: ISO8601DateString
  deletedAt: ISO8601DateString
}

export type WarehouseCoefficient = {
  id: number
  name: string
  type: string
  marketplaceCode: MallCode
  coefficientPercent: number
  logisticPerLiter: number
  logisticExtraLiter: number
  storagePerUnit: number
  storageExtraUnit: number
  createdAt: ISO8601DateString
  deletedAt: ISO8601DateString
}

export type PrimeCostResponse = {
  article: string
  primeCost: number
}

export type ProductNameVersion = {
  name: string
  ts: ISO8601DateString
}

export type ProductDescriptionVersion = {
  version: ISO8601DateString
  text: string
}

export type StartExportResponse =
  | { id: number }
  | { error: 'ExportLimitReached'; values: { interval: number; max: number } }

export type AvailableCharacteristic = {
  code: string
  count: number
  min: number | null
  max: number | null
  percentiles: Record<number, number>
}

export type CharacteristicValue = { value: string; count: number }

type RangeFilter<T extends string> = {
  [key in `${T}${'From' | 'To'}`]?: number
}

type DateRangeFilter<T extends string> = {
  [key in `${T}${'From' | 'To'}`]?: ISO8601DateString
}

export const rangeFilters = [
  'minPrice',
  'maxPrice',
  'minMsrp',
  'maxMsrp',
  'gmvGrow',
  'salesGrow',
  'inventory',
  'review',
  'reviewGrow',
  'ratingsCaptured',
  'rate',
  'order',
  'soldOrdersPercent',
] as const
export const dateRangeFilters = [
  'created',
  'observed',
  'reviewFirst',
  'reviewLast',
  'available',
  'outstocked',
  'archived',
  'deleted',
] as const

export type ProductRangeFilterType =
  | (typeof rangeFilters)[number]
  | (typeof dateRangeFilters)[number]

type ListSort<T extends string> = `${T}_asc` | `${T}_desc`

export type ProductListFilter = {
  name?: string
  mpn?: string
  cmmf?: string

  urls?: string[]
  retailerIds?: string[]

  periodType?: 'grow' | 'calendar'
  periodMeasure?: 'D' | 'W' | 'M' | 'Y'
  periodNumber?: string

  statuses?: ProductStatus[]
  inventoryTypes?: InventoryType[]
  countries?: string[]
  sort?:
    | ListSort<
        | 'created'
        | 'added'
        | 'observed'
        | 'deleted'
        | 'rate'
        | 'discount'
        | `discount_${'min' | 'avg' | 'max'}`
        | `msrp_${'min' | 'avg' | 'max'}`
        | 'likes'
        | `price_${'min' | 'avg' | 'max'}`
        | 'review'
        | `review_${'first' | 'last' | 'grow'}`
        | 'mall_reviews'
        | 'rating_count'
        | 'order'
        | 'order_grow'
        | 'sales'
        | 'sales_grow'
        | 'gmv'
        | 'gmv_grow'
        | 'id'
        | 'inventory'
        | `inventory_${'sum' | 'count' | 'min' | 'max'}`
        | 'sold_orders_percent'
      >
    | 'id'
    | 'gmv'
    | 'sales'
    | 'order'
    | 'review'
    | 'likes'
    | 'rate'

  categoriesIds?: number[]
  subjectsIds?: number[]
  merchantsIds?: number[]
  brandsIds?: number[]
  tagsIds?: number[]

  characteristicCountry?: string
  characteristicColor?: string
  characteristicVolumeMin?: number
  characteristicVolumeMax?: number
  characteristicQuantityMin?: number
  characteristicQuantityMax?: number
  characteristicWeightMin?: number
  characteristicWeightMax?: number

  limit?: number
  after?: number
} & RangeFilter<(typeof rangeFilters)[number]> &
  DateRangeFilter<(typeof dateRangeFilters)[number]>

export type ProductListItemsSummary = {
  total: number
  reviews: number
  avgRate: number
  avgPrice: number
  soldOrdersPercent: number
  sales: number
  gmv: number
}

export type AggregatePriceInfo = {
  min: number | null
  avg: number | null
  max: number | null
}

export type AggregatePropertyInfo = {
  total: number | null
}

export type ProductListItemPeriod = {
  orders: number | null
  reviews: number | null
  gmv: number | null
  sales: number | null
}

export type LegacyProductInfo = {
  id: number
  categories: Category[]
  variations: ProductVariation[]
  stock: number | null
  isStockAvailable: boolean | null
  likes: number | null
  reviewsCount: number | null
  sales: AggregatePropertyInfo | null
  gmv: AggregatePropertyInfo | null
  review: AggregatePropertyInfo | null
  question: AggregatePropertyInfo | null
  reviewCaptured: AggregatePropertyInfo | null
  order: AggregatePropertyInfo | null
  lastObserved: ISO8601DateString
  firstObserved: ISO8601DateString
  mallUrl: string
  title: string
  externalId: string
  code: string
  mpn: string | null
  rate: number | null
  variationCount: number | null
  brand: Brand | null
  merchant: Merchant | null
  merchants: Merchant[]
  images: string[]
  createdAt: ISO8601DateString
  cmmf: string | null
  mall: string
  statuses: Status[]
}

export type ProductListItem = ProductInfo & {
  reviewFirstObserved: ISO8601DateString | null
  reviewLastObserved: ISO8601DateString | null
  questionFirstObserved: ISO8601DateString | null
  questionLastObserved: ISO8601DateString | null
  period: ProductListItemPeriod | null
  content: ContentStat | null
  soldOrdersPercent: number | null
  merchantsCount: number | null
}

export type ListingDTO<T> = {
  total: number
  items: T[]
}

export type FilterPresetType = 'product_list' | 'keyword_list'

export type FilterPresetInfo = {
  id: number
  name: string
  type: FilterPresetType
  mall: string
}

export type FilterPreset = FilterPresetInfo & {
  data: object
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
}

export type NewUser = {
  phone: string
  fullName?: string
  email?: string
  plainPassword?: string
  authCode?: string
}

export type LoginData = {
  username: string
  password?: string
}

export type FormValidationErrors = {
  error: 'FormValidationErrors'
  formErrors: { [key: string]: string[] }
}

export type AuthCodeType = 'call' | 'sms'

export type RegisterResponse =
  | { status: 'UserRegistered'; userId: number }
  | {
      status:
        | 'UserEmailExists'
        | 'UserPhoneExists'
        | 'AuthCodeMaxAttempts'
        | 'AuthCodeExpired'
        | 'AuthCodeInvalid'
    }

export type RegisterSendAuthCodeResponse =
  | { status: 'AuthCodeSent'; phone: string; type: AuthCodeType }
  | { status: 'AttemptsLimitReached'; phone: string; type: AuthCodeType; seconds: number }
  | { status: 'CaptchaFailed' }

export type LoginResponse =
  | {
      status:
        | 'AuthSuccess'
        | 'UserNotFound'
        | 'InvalidCredentials'
        | 'UserBanned'
        | 'PasswordRequired'
    }
  | { status: 'AuthNeedTwoFactor'; phone: string; type: AuthCodeType }
  | { status: 'AttemptsLimitReached'; seconds: number }

export type LoginSubmitAuthCodeResponse = {
  status:
    | 'AuthSuccess'
    | 'AuthCodeInvalid'
    | 'AuthCodeMaxAttempts'
    | 'AuthCodeExpired'
    | 'TwoFactorNotInProgress'
}

export type RecoverySendAuthCodeResponse =
  | {
      status: 'AuthCodeSent'
      phone: string
      type: AuthCodeType
    }
  | { status: 'UserNotFound'; phone: string; type: AuthCodeType }
  | { status: 'AttemptsLimitReached'; seconds: number; phone: string; type: AuthCodeType }

export type RecoveryResetPasswordResponse =
  | {
      status: 'UserPasswordReset'
      phone: string
      username: string
      password: string
    }
  | { status: 'AuthCodeInvalid' | 'AuthCodeExpired' | 'AuthCodeMaxAttempts' }

export type WebauthnAddOptions = {
  challenge: string
  rp: {
    id: string
    name: string
  }
  user: {
    id: string
    name: string
    displayName: string
  }
  pubKeyCredParams: {
    type: 'public-key'
    alg: number
  }[]
  authenticatorSelection: {
    userVerification: 'required' | 'preferred' | 'discouraged'
  }
  attestation: 'none' | 'indirect' | 'direct'
  excludeCredentials: {
    id: string
    type: 'public-key'
  }[]
  status: 'ok' | 'error'
  errorMessage: string
}

export type WebauthnAuthenticatorCreate = {
  id: string
  type: 'public-key'
  rawId: string
  response: {
    clientDataJSON: string
    attestationObject: string
  }
}

export type WebauthnAuthenticator = {
  id: string
  type: 'public-key'
}

export type WebauthnLogInOptions = {
  challenge: string
  rpId: string
  allowCredentials: {
    id: string
    type: 'public-key'
  }[]
  userVerification: 'required' | 'preferred' | 'discouraged'
  status: 'ok' | 'error'
  errorMessage: string
}

export type WebauthnLogInRequest = {
  id: string
  type: 'public-key'
  rawId: string
  response: {
    clientDataJSON: string
    authenticatorData: string
    signature: string
    userHandle: string
  }
}

export type PromoCode = {
  code: string
  freeDays: number
  tariffs: {
    mall: string
    pricePerMonth: number
    pricePer3Months: number
    pricePer6Months: number
    pricePerYear: number
  }[]
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
}

export type PersonContactRequest = {
  phone: string
  name: string
  email?: string
}

export type CompanyContactRequest = {
  phone: string
  email: string
  name: string
  company: string
}

export type CategoryListFilter = {
  from: ISO8601DateString
  to: ISO8601DateString
  limit?: number
  after?: number
  categoryId?: number | null
  brandsIds?: number[]
  merchantsIds?: number[]
  sort?: ListSort<
    | 'sales'
    | 'gmv'
    | 'review'
    | 'brands_total'
    | 'rating_avg'
    | 'inventory_avg'
    | 'merchants_total'
    | 'products_avail'
    | 'products_sales'
  >
  inventoryType?: 'merchant' | 'marketplace' | null
}

export type CategoryStatValue = {
  share: number | null
  brandsTotal: number | null
  merchantsTotal: number | null
  productsTotal: number | null
  productsObserved: number | null
  productsAvailable: number | null
  productsReviews: number | null
  productsSales: number | null
  availability: number | null
  sales: number | null
  gmv: number | null
  reviewCount: number | null
  ratingDistr: number[] | null
  wSoldPrice: number | null
  specificGmv: number | null
  specificSales: number | null
  inventoryAvg: number | null
  ratingAvg: number | null
  soldProductsShare: number | null
}

export type CategoryStatChartsQuery = {
  categoryId?: number
  from: ISO8601DateString
  to: ISO8601DateString
  brandsIds?: number[]
  merchantsIds?: number[]
  metric: CategoryStatChartType
  inventoryType?: 'merchant' | 'marketplace' | null
}

export type CategoryStatCharts = Array<CategoryStatValue & { date: ISO8601DateString }>

export type CategoryStatChartType = keyof CategoryStatValue

export type CategoryStatWithGrowth = CategoryStatValue & { growth: CategoryStatValue }

export type CategoryStat = CategoryStatWithGrowth & {
  category: Category
}

export type CategoryList = {
  categories: CategoryStat[]
  current: Category
  total: CategoryStatWithGrowth
}

export type CategoryStatBrand = CategoryStatValue & {
  brand: Brand
  rank: number
  growth: CategoryStatValue
}

export type CategoryStatMerchant = CategoryStatValue & {
  merchant: Merchant
  rank: number
  growth: CategoryStatValue
}

export type CategoryPriceRange = {
  categoryId: number
  name: string
  priceFrom: number
  priceTo: number
  productsCount: number
  brandsCount: number | null
  merchantsCount: number | null
  gmv: number
}

export type Keyword = {
  suggest: string
  feedId: number
  phraseId: number
  rank: number
  rankDiff: number | null
  sales: number | null
  salesDiff: number | null
  gmv: number | null
  gmvDiff: number | null
  gmvSpecific: number | null
  gmvSpecificDiff: number | null
  salesSpecific: number | null
  salesSpecificDiff: number | null
  rating: number | null
  ratingDiff: number | null
  competition: number | null
  competitionDiff: number | null
  wSoldPrice: number | null
  wSoldPriceDiff: number | null
  weight: number | null
  searchVolume: number | null
  searchVolumeDiff: number | null
  totalProducts: number | null
  totalProductsDiff: number | null
  totalMerchants: number | null
  totalMerchantsDiff: number | null
  totalBrands: number | null
  totalBrandsDiff: number | null
  productsSold: number | null
  productsSoldDiff: number | null
  productsObserved: number | null
  productsObservedDiff: number | null
  reviews: number | null
  reviewsDiff: number | null
  lastObserved: ISO8601DateString
  categories: Category[]
  url: string | null
}

export type KeysWithDiff<T extends { [k: string]: any }> = keyof {
  [K in keyof T as `${string & K}Diff` extends keyof Keyword ? K : never]: number
}

type KeywordListSort<T extends string> = ListSort<T | `${T}_diff`>

export const keywordRangeFilters = [
  'totalProducts',
  //'productsObserved',
  //'productsSold',
  'sales',
  'gmv',
  'wSoldPrice',
  'searchVolume',
  //'competition',
  //'rank',
  'totalMerchants',
  //'totalBrands',
  'reviews',
  'rating',
  //'salesSpecific',
  //'gmvSpecific',
] as const

export type KeywordRangeFilterType = (typeof keywordRangeFilters)[number]

export type KeywordListFilter = {
  suggest?: string
  sort?: KeywordListSort<
    | 'suggest'
    | 'sales'
    | 'gmv'
    | 'search_volume'
    | 'rank'
    | 'total_products'
    | 'products_sold'
    | 'brands_total'
    | 'merchants_total'
    | 'rating'
    | 'competition'
    | 'w_sold_price'
    | 'sales_specific'
    | 'gmv_specific'
    | 'products_observed'
    | 'reviews'
  >
  phrasesIds?: number[]
  categoriesIds?: number[]
  merchantsIds?: number[]
  brandsIds?: number[]
  limit?: number
  after?: number
} & RangeFilter<KeywordRangeFilterType>

export type KeywordBrand = {
  suggest: string
  feedId: number
  brandId: number
  brandName: string
  rank: number | null
  sales: number | null
  gmv: number | null
  gmvSpecific: number | null
  salesSpecific: number | null
  rating: number | null
  wSoldPrice: number | null
  totalMerchants: number | null
  productsSold: number | null
  productsObserved: number | null
  reviews: number | null
}

export type KeywordMerchant = {
  suggest: string
  feedId: number
  merchantId: number
  merchantName: string
  rank: number | null
  sales: number | null
  gmv: number | null
  gmvSpecific: number | null
  salesSpecific: number | null
  rating: number | null
  wSoldPrice: number | null
  totalBrands: number | null
  productsSold: number | null
  productsObserved: number | null
  reviews: number | null
}

export type KeywordStatValue = {
  date: ISO8601DateString
  rank: number
  sales: number | null
  gmv: number | null
  gmvSpecific: number | null
  salesSpecific: number | null
  rating: number | null
  wSoldPrice: number | null
  searchVolume: number | null
  totalProducts: number | null
  totalMerchants: number | null
  totalBrands: number | null
  productsSold: number | null
  reviews: number | null
}

export type KeywordStatChartType = keyof Omit<KeywordStatValue, 'date'>

export type UserKeyword = {
  id: number
  mall: MallCode
  text: string
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString
  state: 'active' | 'processing'
}

export type UserKeywordList = {
  page: number
  pageSize: number
  totalCount: number
  items: UserKeyword[]
}

export type UserKeywordStats = {
  current: number
  max: number
}

export type QuantileRequest = {
  metrics: KeysWithDiff<Keyword>[]
  quantiles: number[]
}

export type QuantileResponse = {
  metric: KeysWithDiff<Keyword>
  quantiles: number[]
}[]

export type QuantileData = Record<KeysWithDiff<Keyword>, number[]>

export type AiFeatureName = 'generate-description' | 'assistant'

export type CustomReport = {
  code: string
  title: string
  granularity: 'days' | 'months' | 'quarters'
  description: string
  fileName: string
}

export type CustomReportCategory = {
  title: string
  exports: CustomReport[]
}

export type ActionpayAction = 'confirmed_signup' | 'paid_order'

export const actionpayGoalIds: Record<ActionpayAction, number> = {
  confirmed_signup: 23092,
  paid_order: 23093,
} as const

export type ExternalAdvertisingPublicationListFilter = {
  limit?: number
  after?: number
  sort?: ListSort<'lastSeenAt' | 'createdAt'>
}

export type ExternalAdvertisingPublication = {
  network: 'instagram' | 'telegram'
  url: string
  text?: string
  createdAt: ISO8601DateString | null
  lastSeenAt: ISO8601DateString
  commentCount: number | null
  reactionCount: number | null
  viewCount: number | null
}

export type ExternalAdvertisingSearchListFilter = {
  limit?: number
  after?: number
  sort?: ListSort<'createdAt'>
  createdAt?: { from: ISO8601DateString; to: ISO8601DateString }
  state?: ('created' | 'ongoing' | 'successful' | 'failed')[]
}

export type ExternalAdvertisingSearch = {
  mall: MallCode
  productId: string
  state: 'created' | 'ongoing' | 'successful' | 'failed'
  createdAt: ISO8601DateString
}

export type ExternalAdvertisingPublicationCount = {
  productId: string
  count: number
}
